import React from 'react'
import {Box, Stack} from '@mui/material'
import { WhatsApp, Email } from '@mui/icons-material'
import {NavLink} from 'react-router-dom'

import logo from './logo.svg'
import useStyles from './styles'

const Header = () => {
  const classes = useStyles()
  const whatsApp = process.env.REACT_APP_WHATSAPP || 'https://wa.me/33757917110'
  const email = process.env.REACT_APP_EMAIl || 'contact@kelips.fr'

  return <Box className={classes.root}>
    <Stack direction="row" alignItems="center" justifyContent="center" gap={2}>
      <Box className={classes.logoWrapper}>
        <NavLink to="/">
          <img src={logo} alt="Logo Kelips Assurance" width="100%"/>
        </NavLink>
      </Box>
      <a href={whatsApp}>
        <Stack direction="row" alignItems="center" className={classes.contactWrapper} gap={1}>
          <WhatsApp />
        </Stack>
      </a>
      <a href={`mailto:${email}`}>
        <Stack direction="row" alignItems="center" className={classes.contactWrapper} gap={1}>
          <Email />
        </Stack>
      </a>
    </Stack>
  </Box>
}

export default Header
