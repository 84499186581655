import React, {useContext, useReducer} from 'react'
import {Box} from '@mui/material'
import {mergeDeepRight} from 'ramda'

import TarificatorContext from './TarificatorContext'
import Form from './Form'
import Pricing from './Pricing'
import useStyles from './styles'

const reducer = (state, action) => {
  switch (action.type) {
  case 'change':
    return mergeDeepRight(state, action.payload)
  default:
    return state
  }
}

const Tarificator = () => {
  const classes = useStyles()
  const defaultState = useContext(TarificatorContext)
  const [state, dispatch] = useReducer(reducer, defaultState)
  const {display} = state

  return <TarificatorContext.Provider value={state}>
    <Box className={classes.root}>
      {display === 'form' && <Form dispatch={dispatch} />}
      {display === 'pricing' && <Pricing dispatch={dispatch} />}
    </Box>
  </TarificatorContext.Provider>
}

export default Tarificator
