import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({spacing}) => ({
  root: {
    backgroundColor: '#333333',
    padding: spacing(4),
  },
  content: {
    maxWidth: '900px',
    margin: 'auto',
  },
  logo: {
    maxWidth: '200px',
  }
}))

export default useStyles
