import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({spacing}) => ({
  root: {
    maxWidth: '900px',
    margin: 'auto',
    padding: spacing(1),
  },
  logoWrapper: {
    maxWidth: '300px',
  },
  contactWrapper: {
    backgroundColor: '#F9F9F9',
    color: '#222',
    padding: spacing(1),
    borderRadius: 1,
  }
}))

export default useStyles
