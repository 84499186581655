import React from 'react'
import {Box} from '@mui/material'
import Tarificator from '../Tarificator'

const Home = () => {
  return <Box>
    <Tarificator />
  </Box>
}

export default Home
