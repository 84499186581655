import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import {CssBaseline, ThemeProvider} from '@mui/material'
import React from 'react'
import numbro from 'numbro'
import numbroFr from 'numbro/languages/fr-FR'

import theme from 'core/theme'
import Layout from 'component/Layout'
import Home from 'feature/Home'

numbro.registerLanguage(numbroFr, true)

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <CssBaseline />
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
