import {createTheme} from '@mui/material'

const theme = createTheme({
  palette: {
    primary: {
      main: '#67B7FF',
    },
    success: {
      main: '#58a264',
    },
    background: {
      default: '#FAFAFF',
    },
    text: {
      primary: '#333',
    }
  },
  typography: {
    fontSize: 15,
    h1: {
      fontSize: 30,
      fontWeight: 600,
      margin: 'auto',
      textAlign: 'center',
      marginBottom: 24,
      textTransform: 'uppercase',
    },
    h2: {
      fontSize: 22,
      fontWeight: 600,
      textAlign: 'center',
      marginBottom: 40,
      textTransform: 'uppercase',
    },
    h3: {
      fontSize: 20,
      fontWeight: 600,
      textAlign: 'center',
      marginBottom: 20,
    },
  }
})

export default theme
