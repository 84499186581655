import {dispatch} from 'ramda-adjunct'

const computeFees = dispatch([
  (vehiclePrice) => vehiclePrice <= 5000 ? 350 : null,
  (vehiclePrice) => vehiclePrice <= 7000 ? 550 : null,
  (vehiclePrice) => vehiclePrice <= 9000 ? 600 : null,
  (vehiclePrice) => vehiclePrice <= 12000 ? 750 : null,
  (vehiclePrice) => vehiclePrice <= 15000 ? 800 : null,
  (vehiclePrice) => vehiclePrice <= 20000 ? 700 : null,
  (vehiclePrice) => vehiclePrice <= 27000 ? 650 : null,
  (vehiclePrice) => vehiclePrice <= 35000 ? 500 : null,
  () => 600,
])

const computeVehiclePriceRate = dispatch([
  (vehiclePrice) => vehiclePrice <= 3000 ? 63 : null,
  (vehiclePrice) => vehiclePrice <= 4000 ? 44 : null,
  (vehiclePrice) => vehiclePrice <= 5000 ? 34 : null,
  (vehiclePrice) => vehiclePrice <= 6000 ? 22.5 : null,
  (vehiclePrice) => vehiclePrice <= 8000 ? 19.8 : null,
  (vehiclePrice) => vehiclePrice <= 9000 ? 17.8 : null,
  (vehiclePrice) => vehiclePrice <= 10000 ? 15 : null,
  (vehiclePrice) => vehiclePrice <= 12000 ? 13.2 : null,
  (vehiclePrice) => vehiclePrice <= 15000 ? 11 : null,
  (vehiclePrice) => vehiclePrice <= 18000 ? 9.8 : null,
  (vehiclePrice) => vehiclePrice <= 20000 ? 9.5 : null,
  (vehiclePrice) => vehiclePrice <= 30000 ? 8.5 : null,
  (vehiclePrice) => vehiclePrice <= 33000 ? 11 : null,
  (vehiclePrice) => vehiclePrice <= 35000 ? 10.5 : null,
  (vehiclePrice) => vehiclePrice <= 38000 ? 11 : null,
  (vehiclePrice) => vehiclePrice <= 40000 ? 13 : null,
  (vehiclePrice) => vehiclePrice <= 43000 ? 14 : null,
  (vehiclePrice) => vehiclePrice <= 46000 ? 13.8 : null,
  (vehiclePrice) => vehiclePrice <= 48000 ? 15.5 : null,
  () => 15.2,
])

const getPricing = (vehiclePrice, accidentsCount) => {
  const fees = computeFees(vehiclePrice)
  const vehiclePriceRate = computeVehiclePriceRate(vehiclePrice)
  const accidentsPricing = accidentsCount * 70

  return (vehiclePriceRate * vehiclePrice / 100 + accidentsPricing + fees) * 1.15
}

export default getPricing
