import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#FAFAFF',
    minHeight: '100vh',
  },
  content: {
    minHeight: '80vh',
  },
}))

export default useStyles
