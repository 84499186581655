import {createContext} from 'react'

const defaultState = {
  vehiclePrice: 5000,
  accidentsCount: 0,
  pricing: null,
  display: 'form',
  phone: null,
  firstName: null,
  lastName: null,
  loading: false,
}

const TarificatorContext = createContext(defaultState)

export default TarificatorContext
