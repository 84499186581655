import React from 'react'
import {Box, Stack} from '@mui/material'
import {arrayOf, node, oneOfType} from 'prop-types'

import Header from 'component/Header'
import Footer from 'component/Footer'

import useStyles from './styles'

const Layout = ({children}) => {
  const classes = useStyles()

  return <Stack className={classes.root}>
    <Box sx={{backgroundColor: 'white', boxShadow: '1px 1px 3px #F0F0F0'}}>
      <Header />
    </Box>
    <Box flex={1} className={classes.content}>
      {children}
    </Box>
    <Footer />
  </Stack>
}

Layout.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
}

export default Layout
