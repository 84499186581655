import {Box, Button, Stack, TextField, Typography} from '@mui/material'
import React, {useContext} from 'react'
import {func} from 'prop-types'

import TarificatorContext from '../TarificatorContext'
import getPricing from '../getPricing'
import useStyles from './styles'

const Form = ({dispatch}) => {
  const classes = useStyles()
  const {vehiclePrice, accidentsCount} = useContext(TarificatorContext)

  const handleChange = (attribute, value) => {
    dispatch({type: 'change', payload: {[attribute]: value === '' ? value : +value}})
  }

  const handleSubmit = e => {
    e.preventDefault()
    const pricing = getPricing(vehiclePrice, accidentsCount)
    dispatch({type: 'change', payload: {pricing, display: 'pricing'}})
  }

  return <form action="" onSubmit={handleSubmit}>
    <Typography variant="h1">Assurance VTC AUTO ET RCP</Typography>

    <Typography my={4}>Soyez couvert pour le transport de personne à titre onéreux !</Typography>

    <Typography variant="h2">Obtenez votre tarif immédiatement !</Typography>

    <Stack gap={4} alignItems="center">
      <Box>
        <TextField label="Valeur du véhicule" type="number" value={vehiclePrice} onChange={e => handleChange('vehiclePrice', e.target.value)} className={classes.control} />
      </Box>
      <Box>
        <TextField label="Nombre de sinistres" type="number" value={accidentsCount} onChange={e => handleChange('accidentsCount', e.target.value)} className={classes.control}/>
      </Box>
      <Box>
        <Button type="submit" variant="contained" size="large">Tarif immédiat</Button>
      </Box>
    </Stack>
  </form>
}

Form.propTypes = {
  dispatch: func.isRequired,
}

export default Form
