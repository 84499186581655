import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({spacing}) => ({
  root: {
    padding: spacing(5, 5),
    display: 'flex',
    justifyContent: 'center',
  },
}))

export default useStyles
