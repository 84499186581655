import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({spacing}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pricing: {
    display: 'block',
    padding: spacing(2, 4),
    textAlign: 'center',
    backgroundColor: 'white',
    maxWidth: '300px',
    margin: spacing(2, 'auto'),
    border: '1px solid #F0F0F0',
    boxShadow: '0 0 5px #F0F0F0',
  },
  control: {
    backgroundColor: 'white',
  },
  covers: {
    marginTop: spacing(2),
    padding: spacing(2),
    '& ul': {
      listStyle: 'none',
      padding: 0,
      margin: 0,
    }
  },
  modal: {
    padding: spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    background: 'white',
    padding: spacing(4),
  }
}))

export default useStyles
