import React from 'react'
import {Box, Link, Typography} from '@mui/material'

import useStyles from './styles'
import logoFooter from './logo-footer.svg'

const Footer = () => {
  const classes = useStyles()

  return <Box className={classes.root}>
    <Box className={classes.content}>
      <img className={classes.logo} src={logoFooter} alt="Logo Kelips"/>
      <Typography sx={{color: '#FFFFFF', fontWeight: 'bold', fontSize: 14}}>
        Kelips est un acteur de référence en matière d&apos;assurance pour les VTC
      </Typography>
      <Typography sx={{color: '#FFFFFF', fontSize: 12}}>
        KELIPS ASSURANCE est immatriculée au RCS de Dijon sous le numéro 801 691 270 et auprès de l&apos;Orias sous le numéro 14003954.
        KELIPS ASSURANCE est régie par le Code des assurances et a souscrit une assurance responsabilité civile professionnelle et une garantie financière en application des articles L.512-6 et L.512-7 du Code des assurances.
        KELIPS ASSURANCE est soumise au contrôle de l’Autorité de Contrôle Prudentiel et de Résolution (ACPR), 4 place de Budapest, 75009 Paris. <br/>
        <Link href={'https://kelips.fr/mentions-legales'} target={'_blank'} rel={'noreferrer'}>Mentions légales</Link>
      </Typography>
    </Box>
  </Box>
}

export default Footer
