import React, {useContext, useState} from 'react'
import {Alert, Box, Button, CircularProgress, Modal, Stack, TextField, Typography} from '@mui/material'
import {Check, ArrowLeft} from '@mui/icons-material'
import {func} from 'prop-types'
import axios from 'axios'
import numbro from 'numbro'

import useStyles from './styles'
import TarificatorContext from '../TarificatorContext'

const Pricing = ({dispatch}) => {
  const classes = useStyles()
  const {pricing, phone, loading, vehiclePrice, accidentsCount, firstName, lastName} = useContext(TarificatorContext)
  const [error, setError] = useState(null)
  const [isSuccess, setSuccess] = useState(false)
  const [isModalOpened, setIsModalOpened] = useState(false)

  const handlePhoneChange = (phone) => {
    dispatch({type: 'change', payload: {phone}})
  }

  const handleFirstNameChange = (firstName) => {
    dispatch({type: 'change', payload: {firstName}})
  }

  const handleLastNameChange = (lastName) => {
    dispatch({type: 'change', payload: {lastName}})
  }

  const sendCallbackRequest = async (e) => {
    e.preventDefault()

    if (!phone || phone === '') {
      return
    }

    dispatch({type: 'change', payload: {loading: true}})
    const url = `${process.env.REACT_APP_KELIPS_URL}/api/callback-request`
    const data = {pricing, vehiclePrice, accidentsCount, firstName, lastName, phone, pricingMonthly: (pricing/12).toFixed(2), origin: 'avtc2'}
    setError(null)

    try {
      const response = await axios.post(url, data)
      if (response.status !== 201) {
        throw new Error()
      }
      setSuccess(true)
    } catch (error) {
      setError('Une erreur est survenue lors de la demande de rappel')
    } finally {
      dispatch({type: 'change', payload: {loading: false}})
    }
  }

  const isPhoneValid = phone && typeof phone === 'string' && phone.length >= 10

  return <Box className={classes.root}>
    <Typography variant="h1">Nous avons un tarif pour vous !</Typography>
    <Typography mb={2} >Votre assurance à partir de</Typography>
    <Box className={classes.pricing}>
      <Typography fontWeight={600} fontSize={30} color="primary">
        {numbro(pricing).divide(12).format({mantissa: 2})} &euro; / mois
      </Typography>
      <Typography fontWeight={600}>
        Soit {numbro(pricing).format({mantissa: 2})} &euro; / an <br/>
      </Typography>
    </Box>

    <Box className={classes.covers}>
      <ul>
        <li>
          <Stack direction="row" gap={1} alignItems="center">
            <Check fontSize={'10px'} fontWeight={800} color="success" />
            <Typography>Assurance auto tous risques</Typography>
          </Stack>
        </li>
        <li>
          <Stack direction="row" gap={1} alignItems="center">
            <Check fontSize={'10px'} fontWeight={800} color="success" />
            <Typography>Transport de personne à titre onéreux</Typography>
          </Stack>
        </li>
        <li>
          <Stack direction="row" gap={1} alignItems="center">
            <Check fontSize={'10px'} fontWeight={800} color="success" />
            <Typography>Responsabilité civile professionnelle</Typography>
          </Stack>
        </li>
        <li>
          <Stack direction="row" gap={1} alignItems="center">
            <Check fontSize={'10px'} fontWeight={800} color="success" />
            <Typography>Assistance 0km</Typography>
          </Stack>
        </li>
        <li>
          <Stack direction="row" gap={1} alignItems="center">
            <Check fontSize={'10px'} fontWeight={800} color="success" />
            <Typography>Indémnité journalière en cas d&apos;accident</Typography>
          </Stack>
        </li>
      </ul>
    </Box>


    <Stack my={4} gap={2}>
      <Typography fontWeight={600}>Le tarif vous intéresse ? Notre commercial vous rappelle !</Typography>
      <Button type="button" variant="contained" color="success" size="large" onClick={() => setIsModalOpened(true)}>
        Je veux être rappelé !
      </Button>
    </Stack>

    <Button type="button"
      variant="text"
      color="info"
      size="small"
      onClick={() => dispatch({type: 'change', payload: {display: 'form'}})}
      startIcon={<ArrowLeft/>}
    >
      Modifier les infos du formulaire
    </Button>

    <Modal open={isModalOpened} onClose={() => setIsModalOpened(false)} className={classes.modal}>
      <Box className={classes.modalContent}>
        <Typography variant="h2" mb={2}>Demande de rappel</Typography>

        {error && <Alert severity="error" sx={{marginTop: 4}}>{error}</Alert>}

        {
          isSuccess && <Alert severity="success" sx={{marginTop: 4}}>
            Votre demande de rappel a bien été transmises ! Un membre de notre équipe va vous rappeler rapidement !
          </Alert>
        }

        {
          !isSuccess && <form action="">
            <Stack direction="column" alignItems="center" gap={2} mt={2}>
              <Box>
                <Typography mb={2}>Veuillez indiquer votre numéro de téléphone afin qu&apos;un gestionnaire vous rappelle !</Typography>
              </Box>
              <Stack gap={2}>
                <TextField label="Votre prénom" value={firstName || ''} onChange={e => handleFirstNameChange(e.target.value)} disabled={loading} className={classes.control} required />
                <TextField label="Votre nom" value={lastName || ''} onChange={e => handleLastNameChange(e.target.value)} disabled={loading} className={classes.control} required />
                <TextField label="Téléphone" value={phone || ''} onChange={e => handlePhoneChange(e.target.value)} disabled={loading} className={classes.control} required />
              </Stack>
              <Box mt={4}>
                <Button type="submit" variant="contained" color="success" onClick={sendCallbackRequest} disabled={loading || !isPhoneValid} startIcon={loading ? <CircularProgress size={14} /> : null}>
                  Je demande à être rappelé !
                </Button>
              </Box>
            </Stack>
          </form>
        }
      </Box>
    </Modal>

  </Box>
}

Pricing.propTypes = {
  dispatch: func.isRequired,
}

export default Pricing
